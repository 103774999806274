import React from 'react';
import ProjectCard from './ProjectCard';
import './projects.scss';
import customers from '../../assets/customers logos.svg';

const Projects = () => {
  return (
    <div className="projects">
      <div className="heading">
        <h1>
          <span>a</span>s<span>k</span> Top projects in the last 5 years
        </h1>
        <button
          onClick={() =>
            window.open(
              'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/logo%2FFINALJAN.pdf?alt=media&token=48cdde29-258c-4fc6-8896-a4717a14b5af'
            )
          }>
          <img
            src="https://img.icons8.com/metro/26/000000/pdf.png"
            style={{ lineHeight: 0 }}
            alt="pdf icon"
          />
          {'  '}
          Download Brochure
        </button>
      </div>
      <ProjectCard />
      <div className="customers">
        <h2>
          <span>our</span> satisfied customers
        </h2>
        <img src={customers} alt="clients logos" />
      </div>
    </div>
  );
};

export default Projects;
