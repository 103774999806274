import React, { useState } from 'react';
import './gallery.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const Gallery = () => {
  const [url] = useState([
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg10.jpeg?alt=media&token=71db1a26-eee3-486e-8fef-f6e28b5cdae7',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg11.jpeg?alt=media&token=d6c6dd36-4f13-4f3f-8d71-8ee986f72d8d',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg12.jpeg?alt=media&token=84fd841a-50d3-4f27-9cd9-4189cb97086a',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg13.jpeg?alt=media&token=ab6cd23d-eb88-4f83-a16d-00e2198c3159',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg14.jpeg?alt=media&token=7728a04f-9151-4498-9139-dac4cb5b9538',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg15.jpeg?alt=media&token=78b4c6ec-77be-42bd-a154-7439c9ae5d77',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg16.jpeg?alt=media&token=2ce667e4-bd18-4f70-b36b-afc81b94fd89',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg17.jpeg?alt=media&token=38a23074-8db7-4364-b52c-338943e31d2b',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg18.jpeg?alt=media&token=acc3bdb9-cc2a-4c88-889e-a7873ea4b9dc',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg19.jpeg?alt=media&token=e891e90e-9fde-405b-a01b-fa0ddd8b2a37',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg20.jpeg?alt=media&token=269ef179-a924-444a-a5aa-4aab18382cd1',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg21.jpeg?alt=media&token=07c489fe-355c-4c1a-a9db-7693e998654c',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg22.jpeg?alt=media&token=0157208d-0e2a-4890-b270-4f5ed4f0fff1',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg23.jpeg?alt=media&token=bde418fd-de49-4980-b6fe-ff59583a2a7f',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg24.jpeg?alt=media&token=9a762cc3-b328-4a7d-b604-f8d5a62e7844',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg25.jpeg?alt=media&token=ce10d503-2d07-4de7-b68c-691f64d6748a',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg26.jpeg?alt=media&token=262ebf41-c3df-41f3-9ce0-0c8159aa978b',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg27.jpeg?alt=media&token=7ea5955f-272d-433d-a6ab-42918fedb94b',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg28.jpeg?alt=media&token=eb733cb8-f9e7-4673-8d6b-3cb7552c8013',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg29.jpeg?alt=media&token=a61d0c31-8185-4b1b-b584-cf23947836bb',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg3.jpeg?alt=media&token=a52e19f2-1c8e-4b53-b384-5589f5714942',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg31.jpeg?alt=media&token=10078d3f-c5f9-4148-b750-0e3ec0265208',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg32.jpeg?alt=media&token=bfe02cab-5ab8-4d52-8555-223319ae4645',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg33.jpeg?alt=media&token=7af3f17a-1296-45fc-a8ab-bbb82c0a916b',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg4.jpeg?alt=media&token=82e49e5d-2555-431d-83ba-968e99f3cfb1',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg7.jpeg?alt=media&token=a660bb70-c8be-4428-8ef5-e0255d618ef1',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg8.jpeg?alt=media&token=70c685ed-3ba3-4386-b718-af0ee92c4527',
    'https://firebasestorage.googleapis.com/v0/b/ask-tech-steel.appspot.com/o/gallery%2Fimg9.jpeg?alt=media&token=3c911403-271e-47c0-9f38-d1dcf7419bb8',
  ]);

  return (
    <div className="gallery">
      <div className="title">
        <h1>
          <span>Our</span> Wall of Pride
        </h1>
      </div>
      <div className="carousel">
        <Carousel
          autoPlay={true}
          stopOnHover={false}
          dynamicHeight={true}
          showIndicators={false}
          showThumbs={false}
          infiniteLoop={true}>
          {url.map((img) => {
            return (
              <div key={img.slice(-12)}>
                <img src={img} alt="Carousel" />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Gallery;
