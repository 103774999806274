import React from 'react';
import krLogo from '../../assets/KR grey.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="brand">
        <span>@2020 ASK TECH, STEEL WORKS COMPANY</span>
      </div>
      <div className="made-by">
        <span>Made by</span>
        <a href="https://www.khalidragi.com/">
          <img src={krLogo} alt="khalid Ragi logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
