import React, { Component } from 'react';
import firebase from '../../config/firebase';
import './contact.scss';
import mobile from '../../assets/tablet.svg';
import emaili from '../../assets/at.svg';

class Contact extends Component {
  state = { fullName: '', email: '', message: '', phone: '' };

  handleSubmit = (e) => {
    const { fullName, email, message, phone } = this.state;
    const newContact = firebase.functions().httpsCallable('newContact');
    newContact({ fullName, email, message, phone })
      .then(() => alert('Success!'))
      .then(() =>
        this.setState({ fullName: '', email: '', message: '', phone: '' })
      )
      .catch((error) => alert(error));

    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  render() {
    const { fullName, email, message, phone } = this.state;
    return (
      <div className="contact">
        <div className="heading">
          <h2>
            <span className="first">Contact</span> a
            <span className="logo">s</span>k tech
          </h2>
        </div>
        <div className="bottom">
          <div className="form">
            <div className="title">
              <h3>Reach out to us!</h3>
              <p>
                Got a question about ASK TECH? Are you interested in partnering
                with us? Contact us:
              </p>
            </div>
            <form name="contact" onSubmit={this.handleSubmit}>
              <input
                type="text"
                placeholder="Full Name"
                name="fullName"
                onChange={this.handleChange}
                value={fullName}
              />
              <input
                type="number"
                placeholder="your phone number"
                name="phone"
                onChange={this.handleChange}
                value={phone}
              />
              <input
                type="email"
                placeholder="your email address"
                name="email"
                onChange={this.handleChange}
                value={email}
              />
              <textarea
                type="text"
                placeholder="message"
                name="message"
                onChange={this.handleChange}
                value={message}
              />
              <button type="submit">Send</button>
            </form>
          </div>
          <div className="contact-info">
            <div className="title">
              <h3>Head office</h3>
              <p>Plot 421, Industrial Zone, Third Settlement, New Cairo.</p>
            </div>
            <div className="info">
              <div className="mobile">
                <div className="icon">
                  <img src={mobile} alt="mobile" />
                </div>
                <div className="num">
                  <p>+201022120000</p>
                  <p>+201205005011</p>
                </div>
              </div>
              <div className="email">
                <div className="icon">
                  <img src={emaili} alt="email" />
                </div>
                <div className="email-e">
                  <p>info@askgroupegypt.com</p>
                </div>
              </div>
            </div>
            <div className="location">
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.3473417651794!2d31.4833959151134!3d29.969446281909633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145823e6721e6797%3A0x471f5303e1fe0ff8!2sASK%20Tech!5e0!3m2!1sen!2ssa!4v1587933008035!5m2!1sen!2ssa"
                  width="330"
                  height="300"
                  frameborder="0"
                  style={{ border: 0 }}
                  title="map"
                  allowfullscreen="false"
                  aria-hidden="false"
                  tabindex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
