import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCEtDCywYjzV5HwGsJX6RUhgEfckKZk4ko',
  authDomain: 'ask-tech-steel.firebaseapp.com',
  databaseURL: 'https://ask-tech-steel.firebaseio.com',
  projectId: 'ask-tech-steel',
  storageBucket: 'ask-tech-steel.appspot.com',
  messagingSenderId: '832402969537',
  appId: '1:832402969537:web:7509159a9686601ae21540',
  measurementId: 'G-XSFEVWPQBK',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;
